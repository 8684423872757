var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',[_vm._v(_vm._s(this.loggedUser.username))]),_c('h4',[_vm._v(_vm._s(_vm.dayWeek)+", "+_vm._s(_vm.day)+" "+_vm._s(_vm.month)+" "+_vm._s(_vm.year))]),_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":"https://www.ksnlaw.com/blog/wp-content/uploads/2018/04/49-600x325.jpg","alt":""}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Encomedas")]),_c('vc-donut',{attrs:{"size":200,"unit":"px","thickness":35,"total":100,"has-legend":true,"legend-placement":"left","sections":[
                  {
                    label: 'Em processamento',
                    value: _vm.percProcessing,
                  },
                  {
                    label: 'Picking Pronto',
                    value: _vm.percPicking,
                  },
                  {
                    label: 'Printing',
                    value: _vm.percPrinting,
                  },
                  {
                    label: 'Packing',
                    value: _vm.percPacking,
                  },
                ]}},[_vm._v("TOTAL: "),_c('br'),_c('h3',[_vm._v(_vm._s(Number(_vm.total)))])]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"/orders"}},[_vm._v("Ver Encomendas")])],1)]),_vm._m(1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":"https://f.i.uol.com.br/fotografia/2020/08/13/15973512325f35a540a967d_1597351232_3x2_md.jpg","alt":""}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Receção do produto")]),_c('p',{staticClass:"card-text"},[_vm._v("Receber encomendas do produto")]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"/receptionProducts"}},[_vm._v("Receber")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":"https://pplware.sapo.pt/wp-content/uploads/2018/12/CTT-1-720x378.png","alt":""}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Fecho de Dia CTT")]),_c('p',{staticClass:"card-text"},[_vm._v("Criar guia de envio CTT")]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"/ctt/close"}},[_vm._v("CTTExpresso")]),_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-right":"10px"},attrs:{"href":"/ctt/closeN"}},[_vm._v("CTT")]),_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-right":"10px"},attrs:{"href":"/ctt/deliveryNotes"}},[_vm._v("Guia de Entrega")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":"https://www.shipbob.com/wp-content/uploads/2019/01/iStock-692898468-2-optimized.webp","alt":""}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Criar Encomenda de Pedidos de Cliente")]),_c('p',{staticClass:"card-text"},[_vm._v("Podes criar uma encomenda de produtos pedidos pelos nosso clientes utilizando a nossa plataforma")]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"/client/createOrder"}},[_vm._v("Ver Pedidos")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":"https://static05.cminds.com/wp-content/uploads/edd/MTrueEditOrderS.png","alt":""}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Encomendas Manuais")]),_c('p',{staticClass:"card-text"},[_vm._v("Formulário para criar novas encomendas")]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"/manualOrder"}},[_vm._v("Criar Encomenda")])])])
}]

export { render, staticRenderFns }